import React from 'react'
import { Button, Container, Jumbotron } from 'reactstrap'
import { Link } from 'gatsby'

const CallToAction = () => (
  <Container className="bg-dark">
    <Jumbotron fluid className="text-center bg-transparent text-light" style={{marginBottom :'0'}}>
      <h1>Envie d&rsquo;en savoir plus&nbsp;?</h1>
      <p className="lead">Contactez-nous dés aujourd&rsquo;hui pour obtenir plus d&rsquo;informations&nbsp;!</p>
      <Link to="/contact">
        <Button size="lg" className="mt-3 mb-auto">
          Cliquez ici
        </Button>
      </Link>
    </Jumbotron>
  </Container>
)

export default CallToAction