import React from 'react'
import PropTypes from 'prop-types'
import SEO from '../components/seo'
import Navigation from '../components/navbar'
import Footer from '../components/footer'
import './bootstrap.scss'
import './layout.css'

const Layout = ({ children, title, description, location }) => (
    <>
      <SEO
        title={title}
        description={description ? description : null}
        url={location} />
      <Navigation />
      {children}
      <Footer />
    </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  location: PropTypes.any
}

export default Layout
