import React, { useState } from 'react'
import {
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarToggler,
  NavItem,
} from 'reactstrap'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'
/* Disabled for now;
import Headroom from 'react-headroom' */

import Logo from '../../static/images/logo.svg'

const Navigation = () => {
  let [opened, setOpened] = useState(false)

  return (
    <>
      <Navbar dark expand="lg" className="sticky-top" style={{ backgroundColor: '#181818', minHeight: '5rem' }}>
        <Container>
          <Link to="/" className="navbar-brand">
            <img
              src={Logo}
              alt="Logo"
              className="navbar-brand navbar-logo"
              style={{ width: 'auto', maxHeight: '4rem' }}
            />
          </Link>
          <NavbarToggler onClick={() => setOpened(!opened)} aria-controls="navbarSupportedContent" aria-expanded={opened ? true : false} aria-label="Toggle navigation" />
          <Collapse isOpen={opened} navbar id="navbarSupportedContent">
            <Nav className="ml-auto" navbar>
              <NavItem>
                <Link to="/" className="nav-link">
                  Accueil
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/historique" className="nav-link">
                  Historique
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/disciplines" className="nav-link">
                  Disciplines
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/instructeurs" className="nav-link">
                  Instructeurs
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/galerie" className="nav-link">
                  Galerie
                </Link>
              </NavItem>
              <NavItem>
                <Link to="/contact" className="nav-link">
                  Contact
                </Link>
              </NavItem>
              <NavItem>
                <a href="https://www.facebook.com/9.dragons.Nice/" className="nav-link" rel="noopener noreferrer" target="_blank">
                  <FontAwesomeIcon icon={faFacebook} size="lg"/>
                </a>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default Navigation
