import React from 'react'
import { Container, Row } from 'reactstrap'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons'

const Footer = () => (
  <footer style={{ backgroundColor: 'black' }} className="text-light">
    <Container style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
      <Row className="text-center text-xs-center text-sm-left text-md-left">
        <div className="col-xs-12 col-sm-6 col-md-4 mb-4">
          <h5>Académie Martiale des 9 Dragons</h5>
          <p className="text-muted">
            L&rsquo;association AM9D académie martiale des 9 dragons vous propose
            un eventail important de disciplines pour 1 seule inscription :
            Kung-Fu traditionnel, arts martiaux mixtes, self-défense Jeet Kune Do,
            Kali-Eskrima, art martial artistique, sabre-laser. 
          </p>
          <p className="text-muted">
            En outre, le club propose aussi, par d&rsquo;autres associations, des
            disciplines internes et bien-être des arts martiaux : le Qi-Gong, le
            Tai-Chi-Chuan, le Pilates et Hatha Yoga.
          </p>
        </div>
        <div className="col-xs-12 col-sm-4 col-md-4 mb-4">
          <h5>Plan du site</h5>
          <ul className="list-unstyled">
            <li>
              <Link to="/">
                Accueil
              </Link>
            </li>
            <li>
              <Link to="/historique">
                Historique
              </Link>
            </li>
            <li>
              <Link to="/disciplines">
                Disciplines
              </Link>
            </li>
            <li>
              <Link to="/instructeurs">
                Instructeurs
              </Link>
            </li>
            <li>
              <Link to="/galerie">
                Galerie
              </Link>
            </li>
            <li>
              <Link to="/contact">
                Contact
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-xs-12 col-sm-4 col-md-4 mb-4">
          <h5>Contactez-nous</h5>
          <p className="text-muted">
            25 bis, rue Gubernatis<br />
            06000 Nice<br />
          </p>
          <p className="text-muted">
            <strong>Tél. : </strong><a href="tel:+33493138017">04 93 13 80 17</a>
          </p>
        </div>
      </Row>
      <Row>
        <div className="col-12 text-center text-muted">
          <a href="https://www.facebook.com/9.dragons.Nice/" className="text-muted" rel="noopener noreferrer" target="_blank">
            <FontAwesomeIcon icon={faFacebook} size="2x" />
          </a>
        </div>
      </Row>
      <Row>
        <div className="col-md-12 text-center text-muted mt-3">
          &copy; {new Date().getFullYear()} Académie Martiale des Neuf Dragons. Tous droits réservés.
        </div>
      </Row>
    </Container>
  </footer>
)

export default Footer
